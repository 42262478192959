<template>
  <div>
    <div class="d-flex">
      <nav id="breadcrumbs" class="mb-3">
        <ul>
          <li>
            <router-link :to="{ name: 'company.dashboard' }">
              <a href="#"> <i class="uil-home-alt"></i> </a>
            </router-link>
          </li>
          <li>
            <span> {{
              this.$t('report.company_dashboard.reports') }}
            </span>
          </li>
          <li>{{ this.$t('general.business_partner_license_reports') }}</li>
        </ul>
      </nav>
    </div>

    <div class="section-header mb-lg-2 border-0 uk-flex-middle">
      <div class="section-header-left">
        <h2 class="uk-heading-line text-left">
          <span>{{ this.$t('general.business_partner_license_reports') }} </span>
        </h2>
      </div>
    </div>
    <!--Modal Profie-->

    <!--End Modal Profile -->
    <div class="card">
      <!-- Card header -->
      <div class="card-header actions-toolbar border-0">
        <div class="d-flex justify-content-between align-items-center">
          <div class="row" style="width:100%">
            <div class=" col-md-1  search-icon ">
              <i class="uil-search"></i>
            </div>
            <div class="col-md-11 uk-search uk-search-navbar" style="margin-left:-2rem">
              <input class="uk-search-input shadow-0 uk-form-small" type="search" v-model="filter.search"
                :placeholder="$t('general.search')" autofocus="" />
            </div>
            <!-- <div>
            <a aria-expanded="false" class="btn btn-icon btn-hover btn-circle" href="javascript:void(0)">
              <i class="uil-search"></i>
            </a>
            <div class="uk-drop" style="left: 91px; top: 159.297px;"
                 uk-drop="mode: hover; pos: right-center; offset: 0">
                <span class="uk-search uk-search-navbar uk-width-1-1">
                  <input v-model="filter.search" :placeholder="$t('general.search')" autofocus=""
                         class="uk-search-input shadow-0 uk-form-small" type="search">
                </span>
            </div> -->
          </div>
          <!-- <h4 class="d-inline-block mb-0"></h4> -->
          <div class="d-flex">
            <a :uk-tooltip="$t('filters.order.sort')" aria-expanded="false" class="btn btn-icon btn-hover  btn-circle"
              href="javascript:void(0)" title="">
              <i class="uil-list-ui-alt"></i>
            </a>
            <div class="uk-dropdown" uk-dropdown="pos: bottom-right ; mode: click ;animation: uk-animation-scale-up">
              <ul class="uk-nav uk-dropdown-nav">
                <li class="uk-active">
                  <a href="javascript:void(0)" @click="sortOrder('asc', 'name')">{{ $t("sorting.a_z") }}</a>
                </li>
                <li>
                  <a href="javascript:void(0)" @click="sortOrder('desc', 'name')">{{ $t("sorting.z_a") }}</a>
                </li>
                <li>
                  <a href="javascript:void(0)" @click="sortOrder('asc', 'id')">
                    {{ $t("sorting.created_at_old") }}</a>
                </li>
                <li>
                  <a href="javascript:void(0)" @click="sortOrder('desc', 'id')">
                    {{ $t("sorting.created_at_new") }}</a>
                </li>
              </ul>
            </div>
            <a class="btn btn-icon btn-hover  btn-circle" href="javascript:;" :uk-tooltip="$t('filters.export_excell')"
              @click="exportLicences">
              <i class="fa-file-excel fa"></i>
            </a>
          </div>
        </div>
      </div>
      <!-- Table -->
      <default-loading v-if="user_loading"></default-loading>
      <template v-else>
        <div class="table-responsive">
          <table class="table align-items-center">
            <thead>
              <tr>
                <th scope="col">{{ $t("users.photo") }}</th>
                <th scope="col">ID</th>
                <th scope="col">{{ this.$t('company.list.company_name') }}</th>
                <!--              <th scope="col">Firma Türü</th>-->
                <th scope="col">{{ this.$t('general.license_start_date') }}</th>
                <th scope="col">{{ this.$t('general.license_end_date') }}</th>
                <th scope="col">{{ this.$t('general.days_left_on_license') }}</th>
              </tr>
            </thead>
            <tbody class="list">
              <template v-for="item in formattedItems">
                <tr>
                  <th scope="row">
                    <div class="media align-items-center">
                      <div>
                        <div class="avatar-parent-child" style="width: max-content">
                          <img :src="item.photo_link" :alt="item.name" class="avatar  rounded-circle" />
                        </div>
                      </div>
                    </div>
                  </th>
                  <td>{{ item.id }}</td>
                  <td>{{ item.name }}</td>
                  <!--                <td>{{ item.type }}</td>-->
                  <td>{{ item.start_date }}</td>
                  <td>{{ item.end_date }}</td>
                  <td>{{ item.remaining_day }}</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <pagination :pagination="pagination" class="mt-2 mb-3" @changePage="setPage($event)"></pagination>
      </template>
    </div>
  </div>
</template>

<script>
import reports, {
  ERROR,
  GET_ITEMS,
  ITEMS,
  LOADING,
  MODULE_NAME,
  SUCCESS,
} from "@/core/services/store/reports.module";

import store from "@/core/services";
import moment from "moment";
import axios from "axios";
import Pagination from "@/assets/components/Pagination";
import UserProfile from "@/view/company/User/UserProfile";
import DefaultLoading from "@/view/components/loading/DefaultLoading";

const _MODULE_NAME = MODULE_NAME;

export default {
  name: "ReportLicences",
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }

    registerStoreModule(_MODULE_NAME, reports);
  },
  components: { Pagination, UserProfile, DefaultLoading },
  data() {
    return {
      pagination: null,
      activeItem: null,
      page: 1,
      per_page: 10,
      sort_by: "id",
      sort_desc: "desc",
      formattedItems: [],
      filter: {
        search: null,
        order: "desc",
        sort: "id",
      },
      successMessage: "",
    };
  },
  computed: {
    items: {
      get() {
        return store.getters[_MODULE_NAME + "/" + ITEMS];
      },
      set(value) { },
    },
    user_loading: {
      get() {
        return store.getters[_MODULE_NAME + "/" + LOADING];
      },
      set(value) { },
    },
    errors: {
      get() {
        return store.getters[_MODULE_NAME + "/" + ERROR];
      },
      set(value) { },
    },
    success() {
      return store.getters[_MODULE_NAME + "/" + SUCCESS];
    },
  },
  methods: {
    exportLicences() {
      axios
        .get(
          process.env.VUE_APP_BACKEND_APP_URL +
          "/api/report/export/company-contracts",
          {
            responseType: "blob",
            params: {
              sort: this.filter.sort,
              order: this.filter.order,
              like: this.filter.search,
              report: Math.random() * 9999999
            },
            headers: this.apiHeaders,
          }
        )
        .then((response) => {
          let fileURL = window.URL.createObjectURL(new Blob([response.data]));
          let fileLink = document.createElement("a");
          fileLink.href = fileURL;
          let day = moment().format("YYYYMMDDHHMMSS");
          let fileName = "licence_report_list_" + day + ".xlsx";
          fileLink.setAttribute("download", fileName);
          document.body.appendChild(fileLink);
          fileLink.click();
        });
    },
    filterResult(filter = null) {
      let self = this;
      let filters = {
        like: self.filter.search,
        page: self.page,
        per_page: self.per_page,
        sort: self.filter.sort,
        order: self.filter.order,
      };
      store
        .dispatch(_MODULE_NAME + "/" + GET_ITEMS, {
          url: "api/report/company-contracts",
          filters: filters,
        })
        .then((response) => {
          if (response.status) {
            let results = [];
            let items = response.data;
            this.pagination = items;
            items.data.forEach((item) => {
              let start = moment().format("YYYY-MM-DD");
              let end = moment(item.end_date, "YYYY-MM-DD");
              let remaining_day = moment.duration(end.diff(start)).asDays();
              results.push({
                id: item.id,
                name: item.contractable.name,
                photo_link: item.contractable.logo_link,
                type:
                  item.contractable.type == "institution" ? "Kurum" : "Firma",
                start_date: item.start_date
                  ? moment(item.start_date).format("DD/MM/YYYY")
                  : "-",
                end_date: item.end_date
                  ? moment(item.end_date).format("DD/MM/YYYY")
                  : "-",
                remaining_day:
                  remaining_day > 0
                    ? remaining_day + " gün"
                    : "Lisans Süresi Dolmuş.",
              });
            });
            this.formattedItems = results;
          }
        });
    },
    sortOrder(order, sort) {
      this.page = 1;
      this.filter.order = order;
      this.filter.sort = sort;

      this.filterResult();
    },
    setPage(page) {
      this.page = page;
      this.filterResult();
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    },
    getFilter(value) {
      this.page = 1;
      this.filterResult(value);
    },
  },
  mounted() {
    this.filterResult();
    this.debouncedFilterSearch = _.debounce(this.filterResult, 500);

    if (this.$route.params.succesMessage != null) {
      this.successMessage = this.$route.params.succesMessage;
      this.scrollToTop();
    }
  },
  watch: {
    success: function (val) {
      if (val) {
        this.filterResult();
      }
    },
    "filter.search": function (val) {
      this.debouncedFilterSearch();
    },
    formattedInstitution(value) {
      if (value) {
        setTimeout(() => {
          $(".selectpicker1").selectpicker();
        }, 200);
      }
    },
    formattedUserGroup(value) {
      if (value) {
        setTimeout(() => {
          $(".selectpicker2").selectpicker();
        }, 200);
      }
    },
    formattedPosition(value) {
      if (value) {
        setTimeout(() => {
          $(".selectpicker3")
            .selectpicker("refresh")
            .trigger("change");
        }, 200);
      }
    },
    formattedTitle(value) {
      if (value) {
        setTimeout(() => {
          $(".selectpicker4")
            .selectpicker("refresh")
            .trigger("change");
        }, 200);
      }
    },
    formattedRegion(value) {
      if (value) {
        setTimeout(() => {
          $(".selectpicker5")
            .selectpicker("refresh")
            .trigger("change");
        }, 200);
      }
    },
    formattedDepartment(value) {
      if (value) {
        setTimeout(() => {
          $(".selectpicker6")
            .selectpicker("refresh")
            .trigger("change");
        }, 200);
      }
    },
    formattedBranch(value) {
      if (value) {
        setTimeout(() => {
          $(".selectpicker7")
            .selectpicker("refresh")
            .trigger("change");
        }, 200);
      }
    },
    activeItem(value) {
      if (value) {
        this.getUserDetail(value.id);
      }
    },
  },
  beforeDestroy() {
    const modal = UIkit.modal("#modal-profile");
    if (modal) modal.$destroy(true);
  },
};
</script>

<style scoped>
input[type="button"],
input[type="submit"],
span.btn,
.btn {
  display: inline-block;
}

.uk-alert {
  margin: 0;
}
</style>
